
import { JsonProperty } from '../classes/json-property.annotation';

/**
 * Modele contenant un utilisateur.
 */
export class User {

    /**
     * Convertit un objet Json en instance de User.
     * @param args objet JSON.
     * @return Instance de User.
     */
    public static fromJson(args: any): User {
        let fields = (<any> User)._alias;
        let instance = new User();

        // tslint:disable-next-line:forin
        for (let field in fields) {
            let alias = fields[field];
            instance[field] = args[alias];
        }

        return instance;
    }

    /**
     * Convertit un tableau Json en tableau d'instance de User.
     * @param args tableau JSON.
     * @return Tableau d'instance de User.
     */
    public static fromJsonArray(array: any[]): User[] {
        let users = [];
        array.forEach(function (item) {
            let user = User.fromJson(item);
            users.push(user);
        });
        return users;
    }

    /**
     * Id de l'utilisateur.
     */
    @JsonProperty('id')
    public id: number;

    /**
     * Nom de l'utilisateur.
     */
    @JsonProperty('name')
    public name: string;

    /**
     * Prénom de l'utilisateur.
     */
    @JsonProperty('firstname')
    public firstname: string;

    /**
     * Service de l'utilisateur.
     */
    @JsonProperty('serviceName')
    public serviceName: string;

    /**
     * N° de téléphone interne.
     */
    @JsonProperty('internalPhone')
    public internalPhone: string;

    /**
     * N° de téléphone fixe.
     */
    @JsonProperty('phone')
    public phone: string;

    /**
     * N° de téléphone mobile.
     */
    @JsonProperty('mobile')
    public mobile: string;

    /**
     * Indique si l'utilisateur est administrateur.
     */
    @JsonProperty('isAdmin')
    public isAdmin: boolean;

    /**
     * Login de l'utilisateur.
     */
    @JsonProperty('login')
    public login: string;

    /**
     * Mot de passe de l'utilisateur.
     */
    @JsonProperty('password')
    public password: string;

    /**
     * Confirmation du mot de passe de l'utilisateur
     * (dans le cas d'une création ou d'une mise à jour).
     */
    @JsonProperty('passwordConfirm')
    public passwordConfirm: string;

    /**
     * Retourne le texte affiché dans une liste déroulante.
     */
    get itemName(): string {
        return this.name + ' ' + this.firstname;
    }
}
