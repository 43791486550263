import { HttpHeaders } from '@angular/common/http';

/**
 * Classe représentant les options de la requête.
 */
export class HttpOptions {
    constructor() {
    }

    public headers?: HttpHeaders;
}
