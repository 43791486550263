/**
 * Anotation pour mapper une propriété avec le nom d'une propriété JSON.
 * @param jsonPropertyName Nom de la propriété JSON.
 */
export function JsonProperty(jsonPropertyName: string) {
    // Appellée pour chaque propriété mappée utilisant ce decorateur.
    return function (target: Object, propertyKey: string | symbol) {
        // save mapping reference as static property to target class
        target['constructor']['_alias'] = target['constructor']['_alias'] || {};
        target['constructor']['_alias'][propertyKey] = jsonPropertyName;
    };
}
