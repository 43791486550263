import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Interceptor Http permettant d'ajouter dans les en-têtes des requêtes HTTP le token d'authentification.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Clé utilisé pour stocker le token d'authentification dans le SessionStorage.
     */
    private tokeyKey = 'token';

    /**
     * Constructeur.
     * @param tokenStorage : Instance du service de stockage du token d'authentification sur le navigateur.
     */
    public constructor() {

    }

    /**
     * Intercepte la requête.
     * @param request : Instance de la requête.
     * @param next : Instance de l'interceptor suivant.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = this.getLocalToken();
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }

    /**
     * Retourne la valeur du token d'authentification stockée dans les SessionStorage
     */
    public getLocalToken(): string {
        return sessionStorage.getItem(this.tokeyKey);
    }
}
