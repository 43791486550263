import { Component, OnInit } from '@angular/core';

import { AuthService } from './../common/services/auth.service';

/**
 * Composant affichant la page d'accueil.
 */
@Component({
  selector: 'home',
  styleUrls: [ './home.component.scss' ],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  /**
   * Constructeur.
   * @param authService Service d'authentification.
   */
  constructor(private authService: AuthService) {
    this.authService.checkCredentials();
  }

  /**
   * Initialise le composant.
   */
  public ngOnInit() {
    console.log('`HomeComponent` initialisation.');
  }
}
