import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from './../common/services/loader.service';
import { LoaderState } from './../common/classes/loader-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  public show = false;

  private subscription: Subscription;

  constructor(
    private loaderService: LoaderService
  ) { }

  public ngOnInit() {
    this.subscription = this.loaderService.loaderState
        .subscribe((state: LoaderState) => {
            this.show = state.show;
        });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
