import { Component, ViewEncapsulation } from '@angular/core';

import { AuthService } from './common/services/auth.service';

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  templateUrl: 'app.component.html',
})
export class AppComponent {
  /**
   * Retourne les informations de l'utilisateur connecté.
   */
  public get connectedUser() {
    return this.authService.getUserInfo();
  }

  /**
   * Constructeur.
   * @param authService Service d'authentification.
   */
  constructor(
    private authService: AuthService
  ) {}

  /**
   * Appellé lorsque l'utilisateur clique sur l'icône pour se deloguer.
   */
  public onLogout(): void {
    this.authService.logout();
  }
}
