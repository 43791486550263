import { Injectable } from '@angular/core';
import { LoaderState } from './../classes/loader-state';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class LoaderService {
    public loaderSubject = new Subject<LoaderState>();

    public loaderState = this.loaderSubject.asObservable();

    public show() {
        this.loaderSubject.next(<LoaderState> {show: true});
    }

    public hide() {
        this.loaderSubject.next(<LoaderState> {show: false});
    }
}
