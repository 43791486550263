import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { AboutComponent } from './about';
import { LoginComponent } from './login';
import { NoContentComponent } from './no-content';


const routes: Routes = [
  { path: '',      component: HomeComponent },
  { path: 'home',  component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'contact', loadChildren: './+contact/contact.module#ContactModule'},
  { path: 'distribution-list', loadChildren: './+distribution-list/distribution-list.module#DistributionListModule'},
  { path: 'message', loadChildren: './+message/message.module#MessageModule'},
  { path: 'campaign', loadChildren: './+campaign/campaign.module#CampaignModule'},
  { path: 'user', loadChildren: './+user/user.module#UserModule'},
  { path: 'history', loadChildren: './+history/history.module#HistoryModule'},
  { path: '**',    component: NoContentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
