import { Component,  OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Composant affichant la page 'A propos'.
 */
@Component({
  selector: 'about',
  styleUrls: ['./about.component.scss'],
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
  public ngOnInit() {
    console.log('`AboutComponent` initialisation.');
  }
}
