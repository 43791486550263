import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/toPromise';

import { AuthenticationResult } from './../models/authentication-result';
import { User } from './../models/user';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpOptions } from '../classes/http-options';
import { environment } from 'src/environments/environment';

/**
 * Service utilisé pour gérer l'authentification auprès de l'API CallManager 2.
 */
@Injectable({
    providedIn: 'root'
  })
export class AuthService {
    /**
     * Clé utilisé pour stocker le token d'authentification dans le SessionStorage.
     */
    private tokeyKey = 'token';

    /**
     * Clé utilisé pour stocker les informations de l'utilisateur connecté dans le SessionStorage.
     */
    private loggedUserKey = 'loggedUser';

    /**
     * Url du point d'entrée d'authentification de l'API CallManager 2
     */
    private webServiceUrl: string;

    /**
     * Constructeur du service.
     * @param http Client Http.
     * @param router Gestionnaire de navigation.
     */
    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.webServiceUrl = environment.serverUrl + 'TokenAuth';
    }

    /**
     * Authentifie l'utilisateur auprès de l'API.
     * @param login Identifiant de l'utilisateur.
     * @param password Mot de passe de l'utilisateur.
     * @returns Le résultat de l'authentification.
     */
    public login(login: string, password: string): Promise<AuthenticationResult> {
        return this.http.post<AuthenticationResult>(this.webServiceUrl, { Login: login, Password: password }).toPromise()
            .then((response) => {
                let result = response;

                // Si l'authentification a réussi, le token d'authentification ainsi que les infos sur l'utilisateur connecté
                // sont stockés dans le SessionStorage. L'utilisateur est alors redirigé sur la page Home.
                if (result.state === 1) {
                    let json = result.data as any;
                    sessionStorage.setItem(this.tokeyKey, json.accessToken);
                    sessionStorage.setItem(this.loggedUserKey, JSON.stringify(json.user));
                    this.router.navigate(['home']);
                }

                return result;
            })
            .catch(this.handleError);
    }

    /**
     * Délogue l'utilisateur coté client et le redirige sur la page login.
     */
    public logout() {
        console.log('Deconnection');
        sessionStorage.removeItem(this.tokeyKey);
        sessionStorage.removeItem(this.loggedUserKey);
        this.router.navigate(['login']);
     }

     /**
      * Vérifie que l'utilisateur est authentifié sinon le redirige sur la page login.
      */
    public checkCredentials(): void {
        let token = sessionStorage.getItem(this.tokeyKey);

        if (token == null) {
            this.router.navigate(['login']);
        }
    }

    /**
     * Retourne les informations de l'utilisateur connecté.
     */
    public getUserInfo(): User {
        let userInfo = sessionStorage.getItem(this.loggedUserKey);

        if (userInfo) {
            return User.fromJson(JSON.parse(userInfo));
        } else {
            return null;
        }
    }

    /**
     * Retourne une instance de Headers contenant le token d'authentification pour les requêtes vers l'API.
     */
    public getAuthHeaders(): HttpOptions {
        let token = this.getLocalToken();
        const httpOptions = new HttpOptions();

        if (token) {
            httpOptions.headers = new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            });
        }

        return httpOptions;
    }

    /**
     * Retourne la valeur du token d'authentification stockée dans les SessionStorage
     */
    public getLocalToken(): string {
        return sessionStorage.getItem(this.tokeyKey);
    }

    /**
     * Gère les erreurs retournées par l'API.
     * @param error Erreur retourné par l'API.
     */
    private handleError(error: any): Promise<any> {
        console.error('API returns an error', error);
        return Promise.reject(error.message || error);
    }
}
