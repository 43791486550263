import { Component } from '@angular/core';

import { AuthService } from './../common/services/auth.service';

/**
 * Composant affichant la page de login.
 */
@Component({
    selector: 'login-form',
    templateUrl: 'login.component.html',
    styleUrls: [
        './../common/css/buttons.scss',
        './../common/css/form-component.scss',
        'login.component.scss']
})
export class LoginComponent {
    /**
     * Message affiché en cas d'erreur d'authentification
     */
    public errorMsg = '';

    /**
     * Identifiant saisi par l'utilisateur.
     */
    public userName: string;

    /**
     * Mot de passe saisi par l'utilisateur.
     */
    public password: string;

    /**
     * Constructeur.
     * @param authService Service d'authentification.
     */
    constructor(private authService: AuthService) {}

    /**
     * Appellé lorsque l'utilisateur clique sur le bouton 'Login'.
     */
    public onLogin() {
        this.authService.login(this.userName, this.password).then((result) => {
            if (result.state !== 1) {
                this.errorMsg = result.msg;
            }
        });
    }
}
